/* eslint-disable max-len */
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import Image from 'next/image';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import Cookies from 'js-cookie';
import _ from 'lodash';

import { getGameName, getGameType, getTypeIcon } from '../constant/getGameType';
import { SettingActions } from '../reducers/settingReducer';
import Banner from './Banner';
import Gameh5 from './gameh5';
import Provider from './provider';

import {
  ChevronDown,
} from '../../images';
import BackToTopButton from '../common/BackToTop';

const { Search } = Input;

function App({ data, schemaData }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const { t } = useTranslation('common');
  const { name, category } = router.query;
  const [gameBrands, setGameBrands] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [initialGameList, setInitialGameList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(21);
  const [selectedValue, setSelectedValue] = useState('');
  const userId = Cookies.get('userId');

  const type = getGameType(category || 'slots');

  const fetchGames = async (brands, id) => {
    setGameList([]);
    setInitialGameList([]);

    const filterBrand = brands.find((x) => x._id === id);
    const filterType = filterBrand?.games
      ?.filter((x) => x.type === type.toString())
      .filter((x) => x.status === '0')
      .map((x) => ({ ...x, brandId: filterBrand._id }))
      .filter((x) => x.thumbnail);
    if (filterType) {
      filterType.map((x) => (x.brandThumbnail = filterBrand.thumbnail_2));
    }
    setGameList(filterType);
    setInitialGameList(filterType);
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      setSelectedVendor('');
    };

    // Listen to the router's routeChangeComplete event
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // Empty dependenc

  useEffect(() => {
    const selectedProvider = selectedVendor || name;
    if (selectedProvider) {
      const i = gameBrands.find((x) => x.name === selectedProvider);
      if (i) {
        setSelectedVendor(i._id);
      }
    }
    dispatch(SettingActions.setLoading(false));
  }, [gameBrands, category, name]);

  useEffect(() => {
    if (type === 'recommended' || type === 'hotGames') {
      const result = {
        recommendedGames: [],
        hotGames: [],
      };
      data.brandIcons.map((x) => {
        x.games.map((x1) => {
          data.hotGames.map((x2) => {
            if (x1._id === x2.gameId) {
              let value = {};
              value = x1;
              value.brandId = x._id;
              value.thumbnail = x2.url;
              result.hotGames.push(value);
            }
          });
        });
      });
      if (type === 'recommended') {
        setGameList(result.recommendedGames);
        setInitialGameList(result.recommendedGames);
      } else if (type === 'hotGames') {
        setGameList(result.hotGames);
        setInitialGameList(result.hotGames);
      }
    } else {
      let result = [];
      // Replace icon from brand icons
      // Filter visible brand
      data.brandIcons = data.brandIcons.filter((x) => x.isVisible);

      data.brandIcons.map((x) => {
        if (x[`imageUrl_${type}`]) {
          result.push({
            _id: x.brandId,
            games: x.games, // all games show
            isVisible: x.isVisible,
            name: x.name,
            displayName: x.displayName,
            sorting: x.sorting,
            sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
            thumbnail: x[`imageUrl_${type}`],
            thumbnail_2: x[`imageUrl_${type}_sec`] || x[`imageUrl_${type}`],
          });
        }
      });

      if (type === 12 || type === '12') {
        result = _.sortBy(result, ['sortings[0]']);
      } else if (type === 8 || type === '8') {
        result = _.sortBy(result, ['sortings[1]']);
      } else if (type === 0 || type === '0') {
        result = _.sortBy(result, ['sortings[2]']);
      } else if (type === 4 || type === '4') {
        result = _.sortBy(result, ['sortings[3]']);
      } else if (type === 1 || type === '1') {
        result = _.sortBy(result, ['sortings[4]']);
      } else if (type === 3 || type === '3') {
        result = _.sortBy(result, ['sortings[6]']);
      }

      result = result.map((x) => {
        x.games = x.games.filter((x1) => x1.type === type.toString());
        return x;
      });
      result = result.filter((i) => i.isVisible && i.games.length > 0);
      setGameBrands(result);

      if (selectedVendor) {
        fetchGames(result, selectedVendor);
      } else {
        const gamesResult = [];

        result.map((brand) => {
          brand.games.map((x) => {
            if (x.status !== '1') {
              gamesResult.push({
                ...x,
                brandThumbnail: brand.thumbnail_2,
                brandName: brand.name,
                brandId: brand?._id,
              });
            }
          });
        });

        let allgames = [];
        allgames = gamesResult.filter(
          (x) => x.type === type.toString()
            && x?.thumbnail
            // && x?.gameId
            && x.status !== '1',
        );

        if (name) {
          allgames = allgames.sort((a, b) => {
            // Check if both games have the recommended property
            if (a.recommended !== undefined && b.recommended !== undefined) {
              return (
                a.recommended - b.recommended || a.name.localeCompare(b.name)
              );
            }

            // If one of the games doesn't have the recommended property, prioritize the one that has it
            if (a.recommended !== undefined) {
              return -1;
            }
            if (b.recommended !== undefined) {
              return 1;
            }

            // If neither game has the sorting property, just sort by name
            return a.name.localeCompare(b.name);
          });
        } else {
          allgames = allgames.sort((a, b) => {
          // Check if both games have the sorting property
            if (a.sorting !== undefined && b.sorting !== undefined) {
              return (
                a.sorting - b.sorting || a.name.localeCompare(b.name)
              );
            }

            // If one of the games doesn't have the sorting property, prioritize the one that has it
            if (a.sorting !== undefined) {
              return -1;
            }
            if (b.sorting !== undefined) {
              return 1;
            }

            // If neither game has the sorting property, just sort by name
            return a.name.localeCompare(b.name);
          });
        }

        setGameList(allgames);
        setInitialGameList(allgames);
        setLoading(false);
      }
    }
  }, [router.asPath, type, selectedVendor, data]);

  const handleSearch = (search, filter) => {
    function sortBySpecificIdsFirst(array, specificIds) {
      // Create a set of specific IDs for faster lookup
      const specificIdsSet = new Set(specificIds);

      // Partition the array into two parts: objects with specific IDs and others
      const [specificObjects, otherObjects] = array.reduce((acc, obj) => {
        if (specificIdsSet.has(obj._id)) {
          acc[0].push(obj);
        } else {
          acc[1].push(obj);
        }
        return acc;
      }, [[], []]);

      // Sort the objects with specific IDs based on their order in the specificIds array
      specificObjects.sort((a, b) => specificIds.indexOf(a._id) - specificIds.indexOf(b._id));

      // Concatenate the sorted specific objects with the other objects
      return specificObjects.concat(otherObjects);
    }

    let result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase()));

    if (filter === 'popularity') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => (a.recommended || 0) - (b.recommended || 0));
    }
    if (filter === 'a-z') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }
    if (filter === 'z-a') {
      result = _.filter(initialGameList, (i) => i.name.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.name.localeCompare(a.name));
    }
    if (filter === 'recent') {
      const getRelatedType = (JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || []);
      const specificIds = getRelatedType.filter((x) => x.type === type?.toString()).map((obj) => obj._id);

      result = sortBySpecificIdsFirst(initialGameList, specificIds.toReversed());
    }

    setGameList(result);
  };

  return (
    <>
      <Head>
        <script
          type='application/Id+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <div>
        <BackToTopButton />
        <div className='px-0 md:px-8'>
          <div className='bg-customgray px-3 py-3 flex items-center gap-2 md:rounded-b-lg'>
            <div className='leading-none'><Image src={getTypeIcon(type)} alt='ppx7' /></div>
            <h1 className='capitalize font-medium text-base opacity-70'>
              {t(getGameName(type))}
            </h1>
          </div>
        </div>

        <div className='px-2 md:px-8 mb-10 relative'>
          <div className='mt-2'>
            <Banner type={type} />
          </div>

          <div id='game-container' className='pt-4'>
            <div className='flex items-center justify-end mt-2'>
              <div className='flex items-center mr-2'>
                <label className='font-medium text-sm text-gray-500 hidden md:block'>
                  {t('game.sortBy')}
                </label>
                <div className='custom-select ml-2'>
                  <select
                    className='rounded shadow-sm py-1.5 px-4 capitalize'
                    style={{ minWidth: 150, backgroundColor: '#232C35' }}
                    value={selectedValue}
                    onChange={(event) => {
                      setSelectedValue(event.target.value);
                      handleSearch(searchText, event.target.value);
                    }}
                  >
                    <option value=''>{t('game.filter')}</option>
                    <option value='popularity'>{t('game.popularity')}</option>
                    <option value='a-z'>{t('game.nameAZ')}</option>
                    <option value='z-a'>{t('game.NameZA')}</option>
                    <option value='recent'>{t('game.recentlyPlayed')}</option>
                  </select>
                  <div className='chevron-icon' style={{ width: 10 }}><Image src={ChevronDown} alt='' /></div>
                </div>
              </div>
              <Search
                className='rounded-full search-input shadow-sm'
                placeholder={t('game.search')}
                style={{
                  width: 200,
                  backgroundColor: '#232C35',
                }}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  handleSearch(e.target.value, selectedValue);
                }}
              />
            </div>

            <Provider
              data={data}
              gameBrands={gameBrands}
              gameList={gameList}
              selectedVendor={selectedVendor}
              setSelectedVendor={setSelectedVendor}
              setSearchText={setSearchText}
              className='pb-2 pt-4 sticky top-14 z-[5] theme-bg'
            />

            <div ref={elementRef} style={{ minHeight: '5vh' }}>
              <Gameh5
                games={gameList}
                limit={limit}
                selectedVendor={selectedVendor}
                loading={loading}
                setSearchText={setSearchText}
              />
              {/* <div id='loader-bottom-wrapper' className='text-center'>
            <button className='rounded-full button-secondary-transparent px-8 py-3' onClick={() => handleLimit()}>
              <p className='font-medium text-sm'>Load more</p>
            </button>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
